@font-face {
  font-family: renner-light;
  src: url("../../pages/PublicHome/components/Fonts/Renner_300_Light.ttf");
}

@font-face {
  font-family: renner-book;
  src: url("../../pages/PublicHome/components/Fonts/Renner_400_Book.ttf");
}

@font-face {
  font-family: renner-medium;
  src: url("../../pages/PublicHome/components/Fonts/Renner_500_Medium.ttf");
}

@font-face {
  font-family: renner-semi;
  src: url("../../pages/PublicHome/components/Fonts/Renner_600_Semi.ttf");
}

@font-face {
  font-family: renner-bold;
  src: url("../../pages/PublicHome/components/Fonts/Renner_700_Bold.ttf");
}

.ff-renner-book {
  font-family: renner-book;
}

.ff-renner-light {
  font-family: renner-light;
}

.ff-renner-medium {
  font-family: renner-medium;
}

.ff-renner-semi {
  font-family: renner-semi;
}

.ff-renner-bold {
  font-family: renner-bold;
}

.dropdown-mega-menu {
  padding: 0px !important
}

.popover .grid-menu [class*="col-"] {
  padding: 0px !important
}

.grid-menu [class*="col-"]:nth-child(1n) {
  border-right-width: 0px !important;
}

.right-card-p {
  padding: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.pd-10 {
  padding: 10px;
}

.fs-30 {
  font-size: 30px;
}

.h2-element {
  color: #313948;
  font-size: 18px;
  font-weight: 500;
}

.h2-sub-element {
  color: #3E485C;
  font-size: 14px;
  font-weight: 400;
}

.bg-section {
  background-color: #ECF1FC;
}

.border-bottom {
  border-bottom: 1px solid grey;
}

.mega-menu-item {
  cursor: pointer;
}

.mega-menu-item:hover {
  background-color: #ECF1FC;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
}

.popover-inner {
  width: 1194px;
}

.grid-menu [class*="col-"] {
  border: none!important;
}

@media screen and (max-width: 900px) {
  .popover-inner  {
    width: 800px;
  }
}

.header-icon {
  width: 32px;
}